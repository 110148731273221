import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <main className="page-container">
        <section className="text-container">
          <p className="text-line">
            We are a <strong>software company</strong> focused on crafting delightful consumer
            products for iOS, Android, and other platforms.
          </p>

          <p className="text-line">
            <a href="mailto:ben@brother-digital.com">Reach us by email</a>
          </p>
        </section>

        <footer className="footer-brother">
          <p className="large-brother-text"> 
            <a href="mailto:ben@brother-digital.com">BROTHER DIGITAL</a>
          </p>
        </footer>
      </main>
    </div>
  );
}

export default App;
